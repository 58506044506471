import { request } from '@/utils/request'
import { useEnv } from '@/hooks/env'
const mapOptions = {
  baseURL: useEnv.isDevelopment ? '/' : 'https://apis.map.qq.com',
  withoutCheck: true
}
// 考勤政策
const attendanceApi = {

  // 假期余额列表
  staffUserVacationQuotaList: params => request.get('vacations/staffUserVacationQuota/lists', params, {
    disableThrottling: true
  }),
  // 根据项目获取假期表头
  getProjectVacations: params => request.get('vacations/staffUserVacationQuota/hasProjectVacation', params),
  // 下载模板
  downloadStaffUserVacationBalancesExcel: params => request.post('vacations/StaffUserVacationQuota/export', params),
  // 批量修改员工假期余额
  batchChangeStaffUserVacation: params => request.post('vacations/staffUserVacationQuota/batchChangeStaffUserVacation', params),
  // 修改员工假期余额
  changeStaffUserVacation: params => request.post('vacations/staffUserVacationQuota/changeStaffUserVacation', params),
  // 员工余额变动详情
  getStaffUserVacationChangeDetail: params => request.post('vacations/staffUserVacationQuota/staffUserVacationChangeLogs', params),

  // 假期规则列表
  getVacationRuleList: params => request.get('vacations/vacations/lists', params),
  addOrUpdateVacationRule: params => request.post('vacations/vacations/store', params),
  delVacationRule: id => request.post(`vacations/vacations/destroy/${id}`),


  /* 获取考勤规则列表 */
  getAttendanceList: params => request.get('attendance/attendance_lists/lists', params),

  getAttendanceDetail: id => request.get(`attendance/attendance_lists/show/${id}`),

  /* 获取员工考勤列表 */
  getAttendanceWorkerList: params => request.get('attendance/attendance_rostering/list', params),

  /* 获取考勤详情 */
  // getAttendaceDetail: id => request.get(`Attendace/Attendaces/detail/${id}`),

  /*  添加考勤 */
  addAttendace: params => request.post('attendance/attendance_lists/store', params),

  /* 更新考勤*/
  updateAttendace: params => request.post('attendance/attendance_rostering/store', params),

  /*  删除考勤 */
  deleteAttendace: params => request.post('attendance/attendance_lists/delete', params),

  /* 获取班次列表 */
  getAttendanceShiftList: params => request.get('attendance/attendance_shift/lists', params),

  /* 添加班次 */
  addAttendanceShift: params => request.post('attendance/attendance_shift/store', params),

  /* 获取排班表格 */
  getAttendanceWorkerExcel: params => request.get('attendance/attendance_rostering/make_excel', params, { withoutCheck: true }),

  /* 获取导入排班表表格 */
  importAttendanceWorkerExcel: params => request.post('attendance/attendance_rostering/improt_excel', params),

  /* 时薪列表 */
  getHourlyWageList: params => request.post('attendance/attendance_hourly_wage/lists', params),

  /* 删除时薪 */
  deleteHourlyWage: params => request.get('attendance/attendance_hourly_wage/delete', params),

  /* 编辑时薪 */
  updataHourlyWage: (id, params) => request.post(`attendance/attendance_hourly_wage/update/${id}`, params),

  /* 新增时薪 */
  addHourlyWage: params => request.post('attendance/attendance_hourly_wage/store', params),

  /* 考勤统计列表 */
  getClocksLists: params => {
    if (!params.projectId) {
      return
    }
    return request.get('attendance/clocks/statistic', params)
  },

  /* 考勤统计列表 */
  getClocksDetail: params => request.post('attendance/clocks/daily/logs', params),

  /** 导出原始数据 */
  exportOriginalData: params => request.post('attendance/clocks/export', params),

  /** 导出打卡记录 */
  exportClocks: params => request.post('/attendance/clocks/usersLogs/import', params),

  /* 打卡记录列表 */
  getClocksRecordList: params => {
    if (!params.projectId) {
      return
    }
    return request.get('attendance/clocks/usersLogs', params)
  },

  /* 打卡记录详情 */
  getClocksRecordDetail: params => request.get('attendance/clocks/usersLogs/info', params),

  /** 导入考勤检查 */
  checkImport: params => request.post('Attendance/StaffUserClockSheet/checkImportClockSheet', params),

  /** 导入考勤 */
  importClocks: params => request.post('Attendance/StaffUserClockSheet/importClockSheet', params),

  /** 考情表列表 */
  clockImportList: params => request.get('/Attendance/StaffUserClockSheet/lists', params),

  clockDetailList: params => request.post('/Attendance/StaffUserClockSheetDetailed/lists', params),
  getClockDetailField: params => request.post('/Attendance/StaffUserClockSheetDetailed/get_feild', params),

  /** 批量撤回 */
  batchRecall: params => request.post('/Attendance/StaffUserClockSheetDetailed/onekeyWithdraw', params),

  /** 编辑考情表 */
  editClockDetail: (id, params) => request.post(`/Attendance/StaffUserClockSheetDetailed/update/${id}`, params),

  /** 重新发送考勤表 */
  reSendClocks: params => request.post('/Attendance/StaffUserClockSheet/resendClockSheet', params),

  updateDefaultShift: (id, params) => request.post(`/project/defaultShift/update/${id}`, params),

  /*  修改考勤 */
  // editAttendace: (id, params) => request.post(`staff/staff_Attendace_plan_details/update/${id}`, params),
  // getAddress: params => request.get('ws/place/v1/suggestion', {
  //   key,
  //   ...params
  // }, mapOptions),
  // // https://lbs.qq.com/service/webService/webServiceGuide/webServiceSuggestion
  // getAddressSearch: params => request.get('ws/place/v1/suggestion', {
  //   key,
  //   ...params
  // }, mapOptions),

  // // location= 39.984154,116.307490
  // transformLocation: params => request.get('ws/geocoder/v1', {
  //   key,
  //   ...params
  // }, mapOptions)
}

export { attendanceApi }