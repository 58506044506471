import { useAppStore } from './app'
import { useMenuTreeStore } from './nav-menu-tree'
import { defineStore } from 'pinia'
import { authApi } from '@/api/auth'
import { menuApi } from '@/api/system'
import { ProjectCode, reLoginTips } from '@/utils/request'
import {
  setItem,
  getItem,
  removeItem,
  STORAGE_KEY_TOKEN,
  TABLE_COLLAPSES,
  sessionKey,
  removeSessionItem,
  TAB_TAGS,
} from '@/utils/storage'
import { router, views } from '@/router'
import { Model } from '@/types/model'
import { MenuObject, Permission } from '@/types/global'
import { omit, trimStart } from 'lodash-es'
import { RouteMeta, RouteRecordRaw } from 'vue-router'
import { useTabWithoutStore } from './tab'
import { arrFind } from '@/utils'
import { appRoutes } from '@/router/routes'
interface UserState {

  /** 接口调用权限 */
  token: string | null;

  /** 用户信息 */
  user: Model.User | null;

  /** 所属商户详细信息 */
  group: Model.Group | null;

  /** 所有用户能访问的路由 */
  flatRoutes: string[];

  menus: MenuObject[],

  permissions: string[],

}

export const useUserStore = defineStore('User', {
  state: (): UserState => ({
    token: getItem(STORAGE_KEY_TOKEN),
    user: null,
    group: null,
    flatRoutes: [],
    menus: [],
    permissions: []
  }),

  getters: {
    // 是否登录
    isLogin(): boolean {
      return !!this.token
    },

    // // 获取权限
    // permissions(state): Permission[] {
    //   // 后端权限值为小写，前端权限使用大写作为常量，故需要将用户的权限值转化为大写
    //   return (
    //     this.flatRoutes.map(permission => permission.toUpperCase() as Permission) || []
    //     // []
    //   )
    // },
  },
  actions: {
    // 登录
    async loginSuccess(token: string) {
      setItem(STORAGE_KEY_TOKEN, token)
      this.token = token
      await this.afterLogin()
    },

    // 登录后的处理逻辑，统一获得 token 后需要操作的事情，token 有效期间，重新进入系统获取全局配置
    async afterLogin() {
      let result = await authApi.checkToken()
      if (result.code === ProjectCode.TokenExpired) {
        reLoginTips()
      } else {
        await Promise.all([
          this.getUserInfo(), // 获取用户信息及全局配置
          this.getMenuList(), // 获取菜单列表
        ])
        useMenuTreeStore().initMenuTree()
      }

    },

    setToken(token) {
      this.token = token
      setItem(STORAGE_KEY_TOKEN, token)
    },


    // 获取用户信息
    async getUserInfo() {
      const res = await authApi.getUserInfo()
      // this.user = res
      this.permissions = res?.permissions ?? []

      this.user = omit(res, ['routes', 'permissions'])
      console.log('res.routers', res.routers)

      this.setMenu(res.routers)
      const cacheTabTags = localStorage.getItem(TAB_TAGS)
      if (cacheTabTags) {
        useTabWithoutStore().tabTags = JSON.parse(cacheTabTags)
      }

      useAppStore().refreshTimerData(true) // 登录时开启消息定时器
      // this.user = res.admin
      // this.group = res.group
    },
    setMenu(data: any[]) {
      this.menus = buildAsyncRoutes(data)
    },
    // 获取菜单列表
    async getMenuList() {
      const result = await menuApi.getUserMenuList({})
      let list: string[] = []
      funRouteSub(result, list)
      this.flatRoutes = list
    },


    // 登出
    async logout(requestApi?: boolean, refererPath?: string) {
      requestApi && (await authApi.logout())
      await useAppStore().stopTimer() // 关闭消息定时器
      this.token = null
      removeItem(STORAGE_KEY_TOKEN)
      removeItem(TABLE_COLLAPSES) // 清除gridTable组件缓存的伸展状态
      removeSessionItem(sessionKey.ANTENTDANCE_ERPORT_PROJECT_ID) // 清除考勤模块缓存的项目ID
      useTabWithoutStore().clearTabTags() // 退出登录清除历史标签页记录
      router.replace({
        name: 'login',
        query: {
          refererUrl: refererPath ? encodeURIComponent(refererPath) : '',
        },
      })
    },

    hasPermission(requirePermission: Permission | undefined): boolean {
      if (requirePermission === undefined || this.permissions?.includes('ALL')) {
        return true
      }
      return this.permissions?.includes(requirePermission)
    },
  },
})

function funRouteSub(arr, res) {
  arr.forEach(item => {
    let tmp = item.path
    res.push(tmp)
    if (item.children) {
      funRouteSub(item.children, res)
    }
  })
}
// 生成路由
const buildAsyncRoutes = (routers: any[], menus: any[] = [], parentName = '', parentPath = '') => {
  // 路由徽标数配置 路由权限：接口参数
  const badge = {
    // EMPLOYMENT: dot => dot?.toBeEmployedUsersCount + dot?.toBeConfirmedUsersCount,
    NOTIFICATION_MANAGE: 'unreadMessageCount',
    NOTIFICATION_LIST: 'unreadMessageCount',
    PENDING_REVIEW_PROJECT_LIST: 'masterProjectCount',
    CONTRACT_LIST: 'unsignedContractCount',
    STAFF_USER_LOCK_FACKBOOK: 'clockFeedbackCount',
    OA_APPROVAL: 'pendingApprovalCount',
    WORKER_TO_BE_EMPLOYED: 'toBeEmployedUsersCount',
    WORKER_TO_BE_CONFIRMED: 'toBeConfirmedUsersCount',
    CONTRACT_TO_BE_EXPIRED: 'contractToBeExpiredCount',
    IDCARD_TO_BE_EXPIRED: 'idcardToBeExpiredCount',
    PAYROLL_FEEDBACK_LIST: 'wageFeedbackCount',
    INSURANCE_PERSONNEL_CHANGE: 'insurancePersonnelChangeCount'

  }
  routers.forEach(item => {
    const path = `/${trimStart(item.path, '/')}`
    let component = arrFind(appRoutes, 'meta.requiredPermission', item.permission)
    let menu = {
      path: (parentPath ? parentPath : '') + path.split('?')[0],
      // name: item.path,
      name: item.permission,
      component: views[`../views/${item.component}.vue`],
      meta: {
        parentName,
        component: item.component,
        url: (parentPath ? parentPath : '') + path.split('?')[0],
        title: item.name,
        icon: item.icon,
        collapsed: item.collapsed,
        name: item.permission,
        hideInMenu: !!item.hiddenMenu,
        keepAlive: true,
        toPath: item.toPath,
        showBadge: badge[item.permission]
      } as RouteMeta,
      children: [],
      props: route => Object.assign({}, route.query, route.params),
    }
    if (component) {
      menu.meta = Object.assign(component.meta, menu.meta)
      // menu.name = component.name
    }


    if (item.children) {
      buildAsyncRoutes(item.children, menu.children, item.permission, menu.path)
    }
    router.addRoute('root', menu as RouteRecordRaw)
    menus.push(menu)
  })
  return menus
}