<template>
  <a-input
    v-model:value="contentValue"
    :disabled="props.disabled"
    @update:value="handleChange"
  >
    <template
      v-if="addonBefore && addonBefore.length > 0"
      #addonBefore
    >
      <a-select
        v-model:value="beforeValue"
        style="width: 90px"
        :disabled="props.disabled"
      >
        <a-select-option
          v-for="(item,index) in addonBefore"
          :key="index"
          :value="item?.value"
        >
          {{ item?.label }}
        </a-select-option>
      </a-select>
    </template>
    <template
      v-if="addonAfter && addonAfter.length > 0"
      #addonAfter
    >
      <a-select
        v-model:value="afterValue"
        :disabled="props.disabled"
        style="width: 80px"
      >
        <a-select-option
          v-for="(item,index) in addonAfter"
          :key="index"
          :value="item?.value"
        >
          {{ item?.label }}
        </a-select-option>
      </a-select>
    </template>
  </a-input>
</template>

<script lang="ts" setup name="input-suffix">
import { inputSuffixProps } from './props'
const props = defineProps(inputSuffixProps)
const { addonBefore, addonAfter } = props

const beforeValue = ref()
const contentValue = ref()
const afterValue = ref(1)

watch(() => props.value, value => {
  if (Array.isArray(value) && value.length > 0) {
    const initValue = [...value]
    if (props.addonBefore?.length > 0) {
      beforeValue.value = initValue.shift()
    }
    if (props.addonAfter?.length > 0) {
      afterValue.value = initValue.pop()
    }
    contentValue.value = initValue.pop()
  }
}, { immediate: true })
const values = computed(() => {
  let data = [contentValue.value]
  let before = ''
  let after = ''
  if (beforeValue.value) {
    before = beforeValue.value ?? null
  }
  if (afterValue.value) {
    after = afterValue.value ?? null
  }
  if (props.addonBefore?.length > 0) {
    data.unshift(before)
  }
  if (props.addonAfter?.length > 0) {
    data.push(after)
  }
  return data
})
type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()

function handleChange(value) {
  emits('update:value', unref(values))
}
</script>
