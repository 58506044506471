export const localReference = {
    yesOrNoLocalEnum: [{"label":"是","value":1,"key":"YES"},{"label":"否","value":0,"key":"NO"}],
    freezeLocalEnum: [{"label":"激活","value":1,"key":"ACVITE","color":"green"},{"label":"冻结","value":0,"key":"FREEZE","color":"red"}],
    approvalCheckLocalEnum: [{"label":"待审核","value":0,"key":"WAIT"},{"label":"已审核","value":1,"key":"PASS"},{"label":"拒绝审核","value":2,"key":"REJECT"}],
    sexesLocalEnum: [{"label":"男","value":1,"key":"MALE"},{"label":"女","value":2,"key":"FEMALE"}],
    salaryIsSendStatusLocalEnum: [{"label":"未发送","value":0,"key":"UNSEND"},{"label":"已发送","value":1,"key":"SENDED"}],
    salaryIsLookStatusLocalEnum: [{"label":"未查看","value":0,"key":"UNLOOK"},{"label":"已查看","value":1,"key":"LOOKED"}],
    sureStatusLocalEnum: [{"label":"未确认","value":0,"key":"UNSURE"},{"label":"已确认","value":1,"key":"SURED"}],
    isOpenStatusLocalEnum: [{"label":"权限校验有效","value":1,"key":"USED"},{"label":"权限校验无效","value":0,"key":"UNUSE"}],
    userLogChangeTypeLocalEnum: [{"label":"修改","value":"update","key":"UPDATE"},{"label":"删除","value":"delete","key":"DELETE"},{"label":"新增","value":"create","key":"CREATE"}],
    attendanceTypeLocalEnum: [{"label":"固定班制（固定时间上下班）","value":2,"key":"FIXED"},{"label":"排班制（按排班上下班）","value":1,"key":"SET"}],
    insuranceTypeLocalEnum: [{"label":"无","value":0,"color":"grey","key":"无"},{"label":"减员失败","value":-4,"color":"red","key":"减员失败"},{"label":"增员失败","value":-3,"color":"red","key":"增员失败"},{"label":"需减员","value":-2,"color":"orange","key":"需减员"},{"label":"需增员","value":-1,"color":"blue","key":"需增员"},{"label":"已增员","value":1,"color":"green","key":"已增员"},{"label":"已减员","value":2,"color":"grey","key":"已减员"}],
  }