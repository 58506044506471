import { formatToDateTime, formatToDate, formatToMonth, dateFromNow } from '@/utils/date'
import { DateFormat, FilterDependedKeyAndString, ValidateKeyAndType } from '../props'
import { parseDotStrObjToObj, transformObjToDotStrObj } from '@/utils/object'
import { isUndefined } from 'lodash-es'
import { toErrorPackage } from './toErrorPackage'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import isIdentityCard from 'validator/es/lib/isIdentityCard'
import dayjs from 'dayjs'

/** 解决百分比化小数精度问题 （百分比最多三位小数时） */
const formatter = value => {
  let v = (parseFloat(value) / 100).toString()
  let vArr = v.split('.')
  if (vArr.length == 2) {
    v = `${vArr[0]}.${vArr[1].substring(0, 5)}`
    v = `${parseFloat(v)}`
  } else if (vArr.length == 1) {
    return `${v}`
  }
  return v
}

/** 格式化日期 */
// const formatDate = (needFormatDataKey: string[], format: DateFormat, cloneItem) => {
//   const formatFunctionMap = {
//     'YYYY-MM-DD HH:mm': formatToDateTime,
//     'YYYY-MM-DD': formatToDate,
//     'YYYY-MM': formatToMonth,
//     NOW: dateFromNow
//   }


//   const handle = value => {

//     if (value) {
//       const formatItem = formatFunctionMap[format](value)
//       if (formatItem !== 'Invalid Date') {
//         value = formatItem
//       } else {
//         value = undefined
//       }
//     }
//     return value
//   }
//   needFormatDataKey.forEach(key => {
//     if (key.split('.').length > 1) {
//       // 如果是多级表头
//       let dotStrItem = transformObjToDotStrObj(cloneItem)
//       dotStrItem[key] = handle(dotStrItem[key])
//       cloneItem = parseDotStrObjToObj(dotStrItem)
//     } else {
//       cloneItem[key] = handle(cloneItem[key])
//     }
//   })
//   return cloneItem
// }
// 格式化日期
const formatDate = (needFormatDataKey: string[], format: DateFormat, cloneItem) => {
  const formatFunctionMap = {
    'YYYY-MM-DD HH:mm': formatToDateTime,
    'YYYY-MM-DD': formatToDate,
    'YYYY-MM': formatToMonth,
    NOW: dateFromNow
  }

  const ch = ['YYYY年M月', 'YYYY年M月D日', 'YYYY年M月DD日', 'YYYY年MM月', 'YYYY年MM月D日', 'YYYY年MM月DD日', 'YYYY年MM月DD日 HH:mm:ss', 'YYYY年MM月DD日 HH:mm:ss', 'YYYY年MM月DD日 HH:mm:ss', 'YYYY年MM月DD日 HH:mm:ss', 'YYYY年MM月DD日 HH:mm:ss']

  needFormatDataKey.forEach(key => {
    if (cloneItem[key]) {
      let formatCh = ch.find(item => dayjs(cloneItem[key], item, true).isValid())
      console.log('formatCh', cloneItem[key])

      if (formatCh) {
        cloneItem[key] = dayjs(cloneItem[key], formatCh).format('YYYY-MM-DD HH:mm')
      }
      if (dayjs(cloneItem[key]).isValid()) {
        cloneItem[key] = dayjs(cloneItem[key]).format('YYYY-MM-DD')
      }
    }

    if (key.split('.').length > 1) {
      // 如果是多级表头
      let dotStrItem = transformObjToDotStrObj(cloneItem)
      dotStrItem[key] = dotStrItem[key] && formatFunctionMap[format](dotStrItem[key])
      cloneItem = parseDotStrObjToObj(dotStrItem)
    } else {
      cloneItem[key] = cloneItem[key] && formatFunctionMap[format](cloneItem[key])
    }
  })
  return cloneItem
}


/** 校验数据 */
const validateData = (validateKeyAndType: ValidateKeyAndType, tempUnValidateNumber, cloneItem, row) => {
  const realNameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
  const bankAccountReg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/

  /** 中国身份证校验，结尾的x必须要大写 */
  const idCardXReg = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d{3}[0-9X]$/

  /* 错误信息映射 */
  const errorMassageMap = {
    not: '',
    mobilePhone: '手机号格式不对',
    idCard: '身份证号格式不对',
    idCardCapitalX: '身份证号末尾X要求大写字母',
    bankAccount: '银行卡号格式不对',
    realName: '姓名不能为数字或少于2个中文字',
  }
  // 校验函数映射
  const validateFunctionMap = {
    not: item => item,
    mobilePhone: item => isMobilePhone(item || '', 'zh-CN'),
    idCard: item => isIdentityCard(item || '', 'zh-CN'),
    idCardCapitalX: item => idCardXReg.test(item),
    bankAccount: bankAccount => bankAccountReg.test(bankAccount),
    realName: realName => realNameReg.test(realName),

  }
  let errMsg = ''
  Object.keys(validateKeyAndType).forEach((key, column) => {
    const obj = validateKeyAndType[key]
    const { type = 'not', required, associationValidateString, requiredAssociationKey } = obj
    if (key.split('.').length > 1) {
      console.log('多级')
      // 如果是多级表头
      cloneItem = transformObjToDotStrObj(cloneItem)
    }
    if (required) {
      if (isUndefined(cloneItem[key])) {
        cloneItem[key] = toErrorPackage('必填')
        errMsg = `${key} ${toErrorPackage('必填')} `
        tempUnValidateNumber.push(row)
        return
      }
      // 关联校验 如果某项必填，另一项也需要必填的关联
      if ((cloneItem[key] === associationValidateString) && requiredAssociationKey && isUndefined(cloneItem[requiredAssociationKey])) {
        cloneItem[requiredAssociationKey] = toErrorPackage('必填')
        tempUnValidateNumber.push(row)
        errMsg = `${requiredAssociationKey} ${toErrorPackage('必填')} `
        return
      }
    }
    if (!validateFunctionMap[type](cloneItem[key])) {
      if (isUndefined(cloneItem[key])) {
        return
      }
      // 加上错误信息在具体位置
      errMsg = `${toErrorPackage(errorMassageMap[type])}`
      cloneItem[key] = cloneItem[key] ? `${cloneItem[key]}  ${toErrorPackage(errorMassageMap[type])}` : `${toErrorPackage(errorMassageMap[type])}`
      tempUnValidateNumber.push(row)
    }
  })
  return {
    cloneItem,
    errMsg
  }
}


/** 过滤数据 */
const dataFilter = (cloneDataList, filterDependedKeyAndString: FilterDependedKeyAndString) => cloneDataList.filter(item => {

  if (filterDependedKeyAndString.key.split('.').length > 1) {
    // 如果是多级
    item = transformObjToDotStrObj(item)
  }
  return item[filterDependedKeyAndString.key] === filterDependedKeyAndString.dependedString
})

/** 去百分号 */
const removePercentSign = (cloneDataList, needRemovePercentKey: string[], cloneItem, cloneItemIndex) => {
  needRemovePercentKey.forEach(key => {
    if (key.split('.').length > 1) {
      // 如果是多级表头
      let dotStrItem = transformObjToDotStrObj(cloneItem)
      dotStrItem[key] = dotStrItem[key] && formatter(dotStrItem[key]) // 百分比化小数
      cloneDataList[cloneItemIndex] = parseDotStrObjToObj(dotStrItem)
    } else {
      cloneItem[key] = cloneItem[key] && formatter(cloneItem[key])
    }
  })
  return cloneDataList
}
export {
  formatDate,
  validateData,
  dataFilter,
  removePercentSign
}