import { defineStore } from 'pinia'
import { store } from '..'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { TAB_TAGS } from '@/utils/storage'
import { isArray } from '@/utils/is'

export interface TabInfo {
  name: string,
  path: string,
  title: string,
  keepAlive:boolean,
}
interface TabState {
  loading: boolean
  tabTags: TabInfo[],
}
export const useTabStore = defineStore('Tabs', {
  state: (): TabState => ({
    loading: false,
    tabTags: [],
  }),
  getters: {},
  actions: {
    // 刷新
    refresh(route: RouteLocationNormalizedLoaded) {
      this.loading = true
      const tabTag = this.findTabTag(route)
      if (tabTag) {
        tabTag.keepAlive = false
      }
      setTimeout(() => {
        this.addTabTag(route)
        this.loading = false
        if (tabTag) {
          tabTag.keepAlive = true
        }
      }, 100)
    },
    // 添加选项卡标签
    addTabTag(route: RouteLocationNormalizedLoaded) {
      let info: TabInfo = {
        name: route.name as string,
        path: decodeURIComponent(route.fullPath),
        title: route.meta.title as string,
        keepAlive: !!route.meta.keepAlive,
      }

      if ((route.path !== '/' && route.name !== 'login') && !this.findTabTag(route)) {
        this.tabTags.push(info)
      }
    },
    // 清空选择卡标签
    clearTabTags() {
      localStorage.removeItem(TAB_TAGS)
      this.tabTags = []
    },
    removeByName(name: string | string[]) {
      name = isArray(name) ? name : [name]
      this.tabTags = this.tabTags.filter(item => !name.includes(item.name))
    },
    updateTabTitle(name, route: RouteLocationNormalizedLoaded) {
      const tabTag = this.findTabTag(route)
      if (tabTag) {
        tabTag.title = name as string
      }
    },
    findTabTag(route: RouteLocationNormalizedLoaded): TabInfo | undefined {
      return this.tabTags.find(item => item.path === decodeURIComponent(route.fullPath))
    },
    findTabTagIndex(route: RouteLocationNormalizedLoaded) {
      return this.tabTags.findIndex(item => item.path === decodeURIComponent(route.fullPath))
    },
  },
})

export function useTabWithoutStore() {
  return useTabStore(store)
}
