<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  z-index: 1000;
  padding: 0;
  box-shadow: 0 1px 2px #DBDBDB;
  // background-color: #e94d07;
  // background-color: var(--hc-primary-color);
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px 20px;
    height: 55px;
    background: #FFFFFF;
    box-sizing: border-box;

    .logo-text,
    .logo-collapsed {
      height: 37px;
      width: auto;
    }

    &.collapsed {
      margin: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("@images/logo.png");
    }
  }
}
</style>

<template>
  <a-layout
    class="layout"
  >
    <a-layout-header
      v-if="!systemStore.iframeModel"
      :class="['flex','layout-navbar', systemStore.layout.header.theme === 'theme' ? 'header-theme' : 'header-white' ]"
      :style="{
        height: `${systemStore.layout.header.height}px`,
        lineHeight: `${systemStore.layout.header.height}px`,
        background: systemStore.layout.header.theme === 'theme' ? 'var(--hc-primary-color)' : '#fff',
        color: systemStore.layout.header.theme === 'theme' ? '#fff' : 'rgba(0, 0, 0, 0.88)',
      }"
    >
      <nav-bar />
    </a-layout-header>

    <a-layout>
      <a-layout-sider
        v-if="showSide && !systemStore.iframeModel"
        v-model:collapsed="systemStore.layout.sidebar.collapsed"
        class="layout-sider"
        collapsible
        :width="systemStore.layout.sidebar.width"
        :collapsed-width="48"
        :theme="systemStore.layout.sidebar.theme"
      >
        <Menu />
      </a-layout-sider>
      <a-layout-content class="z-10 overflow-auto pt-[12px] flex flex-col hc-bg">
        <Tab
          v-if="systemStore.layout.tabTag"
          :hide-tab="systemStore.iframeModel"
        />
        <a-spin :spinning="tabStore.loading" />
        <Content
          ref="contentRef"
          class="flex-1 overflow-auto min-w-[900px]"
          :class="systemStore.iframeModel ? '' : 'px-4'"
        />
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import NavBar from './components/navbar/index.vue'
import Menu from './components/menu.vue'
import Content from './components/content.vue'
import { useRoute, useRouter } from 'vue-router'
import { useSystemStore } from '../../store/modules/system'
import Tab from './components/navbar/Tab.vue'
import { useTabStore } from '@/store/modules/tab'
const tabStore = useTabStore()

const systemStore = useSystemStore()
const contentRef = ref<InstanceType<typeof Content>>()
const router = useRouter()
const route = useRoute()

const showSide = computed((() =>
  systemStore.layout.sidebar.open
  && (
    !(systemStore.homePath === route.path && systemStore.layout.splitMenu)
    || systemStore.selectedTopMenu !== systemStore.homePath
  )
))
</script>
