<template>
  <custom-api-select
    :api="attendanceApi.getVacationRuleList"
    :api-params="params"
    :immediate="true"
    value-field="id"
    label-field="vacationName"
  />
</template>
<script lang="ts" setup>
import { attendanceApi } from '@/api/attendance'
import { vacationSelectProps } from './props'
import { useUserStore } from '@/store'
const props = defineProps(vacationSelectProps)
const { apiParams } = props
const params = {
  ...apiParams,
  groupId: useUserStore().user?.groupId
}
</script>
