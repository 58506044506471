<template>
  <custom-api-select
    v-bind="bindAttrs"
  />
</template>
<script lang="ts" setup>
import { clientApi } from '@/api/system'
import { groupApiSelectProps } from './props'
import { useUserStore } from '@/store'
const userStore = useUserStore()
const bindAttrs = computed(() => ({
  api: userStore.user?.isOpen === 0 ? clientApi.getClientSelectList : clientApi.getAdminGroupUsersList,
  labelField: record => `${userStore.user?.isOpen === 0 ? record.text : record.group.text}`,
  valueField: 'groupId',
  placeholder: '请选择组织',
  immediate: true
}))

const props = defineProps(groupApiSelectProps)
</script>
