import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import { commercialInsuranceRoutes } from './commercialInsurance'
import { useEnv } from '@/hooks/env'
const welfareChildrenRoutes = [
  // 社保菜单
  {
    path: 'insurance',
    name: 'insurance',
    component: EmptyLayout,
    meta: {
      title: '社保/公积金管理',
      iconfont: 'icon-emotion-laugh-line',
      requiredPermission: 'INSURANCE',
      needIcon: true,
      noQuick: true
    },
    children: [
      {
        path: 'projects',
        name: 'InsuranceProjectList',
        component: () => import('@/views/insurance/project/index.vue'),
        meta: {
          title: '项目管理',
          iconfont: 'icon-calendar-check-line',
          requiredPermission: 'INSURANCE_PROJECT_LIST',
        },
      },
      {
        path: 'personnels-list',
        name: 'insurancePersonnels',
        component: () => import('@/views/insurance/personnels/personnels-list.vue'),
        meta: {
          title: '参保人员名单',
          iconfont: 'icon-calendar-check-line',
          requiredPermission: 'INSURANCE_PERSONNELS',
        },
      },

      {
        path: 'project/detail/:id',
        name: 'INSURANCE_PROJECT_DETAIL',
        component: () => import('@/views/insurance/project/detail.vue'),
        meta: {
          title: '项目明细',
          hideInMenu: true,
          parentNameForBreadcrumb: 'InsuranceProjectList',
          requiredPermission: 'INSURANCE_PROJECT_DETAIL',
        },
      },
      {
        path: 'project/add-personnel',
        name: 'INSURANCE_ADD_PERSONNELS',
        component: () => import('@/views/insurance/project/add-personnel.vue'),
        meta: {
          title: '申请社保/公积金增员',
          hideInMenu: true,
          parentNameForBreadcrumb: 'InsuranceProjectDetail',
          requiredPermission: 'INSURANCE_ADD_PERSONNELS',
        },
      },
      {
        path: 'insurance-personnel-change',
        name: 'insurancePersonnelChange',
        component: () => import('@/views/insurance/personnelChange/index.vue'),
        meta: {
          title: '参保变更确认',
          iconfont: 'icon-contacts-book-line',
          requiredPermission: 'INSURANCE_PERSONNEL_CHANGE',
        }
      },
      {
        path: 'insurance-charges',
        name: 'INSURANCE_CHARGES',
        component: () => import('@/views/insurance/charges/index.vue'),
        meta: {
          title: '购买扣费确认',
          iconfont: 'icon-survey-line',
          requiredPermission: 'INSURANCE_CHARGES',
        }
      },
      {
        path: 'insurance-policy',
        name: 'insurancePolicy',
        component: () => import('@/views/insurance/policy/index.vue'),
        meta: {
          title: '社保/公积金政策',
          iconfont: 'icon-pages-line',
          requiredPermission: 'INSURANCE_POLICY',
        },
      },
      {
        path: 'insurance-calculator',
        name: 'insuranceCalculator',
        targetHref: `${useEnv.erpUrl}/admin/public/insurance/calculator`,
        meta: {
          title: '社保计算器',
          iconfont: 'icon-pages-line',
          requiredPermission: 'INSURANCE_CALCULATOR',
        },
      },
    ]
  },
  // 商保菜单
  commercialInsuranceRoutes


]
export const welfareRoutes: CustomRouteRecordRaw = {
  path: 'welfare-center',
  name: 'welfareCenter',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(welfareChildrenRoutes),
  meta: {
    title: '福利中心',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以福利为维度内容',
    requiredPermission: 'WELFIRE_CENTER',
    needIcon: true,
    noQuick: true
  },
  children: welfareChildrenRoutes
}

