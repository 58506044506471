<style scoped lang="less">
.left-size {
  display: flex;
}

.logo {
  img {
    height: 24px;
    width: 120px;
  }
}

// 用户hover背景色
.actions > div:hover {
  background-color: rgb(255 255 255 / 20%);
}

.actions {
  align-items: center;
}

</style>

<template>
  <div
    class="flex items-center justify-between flex-1 w-full h-full px-2"
  >
    <div class="flex">
      <Logo />
      <headerMenu v-if="systemStore.layout.splitMenu" />
    </div>
    <div class="flex actions">
      <!-- <BasicIcon
       ? name="icon-notifications_black_24dp"
        class="mt-2 text-2xl text-white cursor-pointer"
        @click=" isShowMessage = true"
      /> -->
      <!-- <a-tooltip
        class="text-2xl"
        title="去admin端"
        placement="bottom"
      >
        <BasicIcon
          name="icon-navigation-arrow1"
          class="mx-2 mt-2 text-2xl cursor-pointer"
          @click="toAdmin"
        />
      </a-tooltip> -->
      <!-- 帮助中心 -->
      <div class="flex items-center w-[50px] h-[50px] justify-center">
        <BasicIcon
          name="icon-help_outline_black_24dp "
          class=" text-2xl cursor-pointer"
          @click=" isShowHelpCenter = true"
        />
      </div>
      <div class="flex items-center w-[50px] h-[50px] justify-center">
        <Notifications />
      </div>
      <div class="flex items-center w-[50px] h-[50px] justify-center">
        <Setting />
      </div>
      <!-- 用户信息 -->
      <div class="ml-2">
        <user-setting @change-group="emits('changeGroup')" />
      </div>
    </div>
    <basicDrawer
      v-bind="helpCenterProps"
      v-model:open="isShowHelpCenter"
    >
      <helpCenter @change="open" />
    </basicDrawer>
    <basicDrawer
      v-bind="messageCenterProps"
      v-model:open="isShowMessage"
    >
      <MesssageCenter />
    </basicDrawer>
  </div>
</template>

<script setup lang="ts">
import BasicIcon from '@/components/icon/basic-icon.vue'
import basicDrawer from '@/components/drawer/basic-drawer.vue'
import UserSetting from './user-setting.vue'
import { useRouter, RouteRecordNormalized } from 'vue-router'
import { useUserStore, useSystemStore } from '@/store'
import helpCenter from '@/views/drawer/help-center.vue'
import { BasicDrawerProps } from '@/components/drawer/props'
import MesssageCenter from '@/views/drawer/messsage-center.vue'
import Notifications from '../notifactions.vue'
import Setting from '../Setting.vue'
import headerMenu from './header-menu.vue'
import Logo from './logo.vue'
import dayjs from 'dayjs'
type EmitEvents = {
  (e: 'handleSideVisible', v: boolean): void,
   (e: 'changeGroup'): void,
}
const emits = defineEmits<EmitEvents>()
const userStore = useUserStore()
const systemStore = useSystemStore()
const appRoute = computed(() => router.getRoutes().find(el => el?.name === 'root') as RouteRecordNormalized)
const isShowHelpCenter = ref<boolean>(false)
const updataTime = ref<string>()
const helpCenterProps = ref<BasicDrawerProps>({
  title: '帮助中心',
  destroyOnClose: true,
  width: 820,
})
const messageCenterProps = ref<BasicDrawerProps>({
  title: '通知中心',
  destroyOnClose: true,
  width: 820,
})


let open = mess => {
  updataTime.value = dayjs(mess).format('YYYY-MM-DD')
  helpCenterProps.value.slug = `更新时间: ${updataTime.value}`
}

// 因为 vue-router 内部使用 Provide 将 router 对象注入到 vue 实例中，useRouter 需要使用 inject 获取 router 实例
// 而 inject 需要用在 setup 顶层，
const router = useRouter()
const isShowMessage = ref<boolean>(false)

</script>
