<template>
  <div>
    <a-upload
      v-bind="bindAttrs"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
      @preview="handlePreview"
    >
      <slot>
        <div v-if="!slots.default">
          <template v-if="props.acceptType === 'img'">
            <upload-outlined />
            <div class="ant-upload-text">
              上传
            </div>
          </template>
          <template v-else>
            <a-button><upload-outlined /> 上传</a-button>
          </template>
        </div>
      </slot>
    </a-upload>
    <a-modal
      :open="previewVisible"
      :footer="null"
      :z-index="1200"
      @cancel="handleCancel"
    >
      <img
        alt="example"
        style="width: 100%;"
        :src="previewImage"
      >
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, toRaw, unref, useAttrs, useSlots, watch } from 'vue'
import { useMessage } from '@/hooks/message'
import { UploadOutlined } from '@ant-design/icons-vue'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store/modules/user'
import { pick, omit } from 'lodash-es'
import { UploadProps } from 'ant-design-vue/es'
import { FileType } from 'ant-design-vue/es/upload/interface'

const attrs = useAttrs()
const slots = useSlots()
const emits = defineEmits(['blur', 'change', 'update:value'])
const props = defineProps({
  maxSize: {
    type: Number as PropType<number>,
    default: 20,
  },
  limitNum: {
    type: Number as PropType<number>,
    default: 0,
  },
  maxNum: {
    type: Number as PropType<number>,
    default: 5,
  },
  fieldName: {
    type: String as PropType<string>,
  },
  fileLists: {
    type: Array,
    default: [],
  },
  value: {
    type: Array as PropType<File[]>,
    default: () => [],
  },
  accept: {
    type: Array as PropType<string[]>,
  },
  acceptType: {
    type: String as PropType<'img' | 'file'>,
    default: 'file',
  },
  convert: {
    type: Boolean,
    default: true,
  }
})
const fileIds = ref<any[]>(props.value?.length > 0 ? props.value.filter(item => item?.id).map(item => item.id) : [])
const { uploadApiUrl } = useEnv
const userStore = useUserStore()


const getAccept = computed(() => {
  if (props.accept) {
    return props.accept
  }
  return props.acceptType === 'img' ? ['jpg', 'jpeg', 'png'] : ['doc', 'docx', 'xls', 'xlsx', 'ofd', 'pdf', 'jpg', 'jpeg', 'png', 'zip', 'rar', 'tar', '7z', 'mp4']
})
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${userStore.token}`,
}))
const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: props.acceptType,
  // fileable_field: props.fieldName,
}))


// upload 组件值，设置该值，使得 upload 组件为受控组件。上传的状态及图片的显示，由 files 控制
const files = ref<UploadProps['fileList']>([...props.fileLists])
watch(() => props.fileLists, val => {
  files.value = [...val]
}, { deep: true })
watch(() => props.value, val => {
  if (val?.length === 0) {
    files.value = []
  }
  const ids = []
  const finds = val.filter(item => typeof (item) === 'object').map(item => {
    ids.push(item.id)
    return {
      uid: item.name,
      name: item.name,
      status: 'done',
      url: item.url,
    }
  })
  if (ids.length > 0 && props.convert) {
    emits('update:value', ids)
  }
  if (finds.length > 0) {
    files.value = finds
  }

})
onMounted(() => {

  props.value?.forEach(fileObj => {
    // const fileName = getFileName(fileObj.path)
    if (typeof fileObj == 'object') {
      files.value?.push({
        id: fileObj.id,
        uid: fileObj.name,
        name: fileObj.name,
        status: 'done',
        url: fileObj.url,
      })
    }
    if (fileIds.value.length > 0 && props.convert) {
      emits('update:value', unref(fileIds))
    }
  })
  if (fileIds.value.length > 0 && props.convert) {
    emits('update:value', unref(fileIds))
  }
})

const bindAttrs = computed(() => ({
  fileList: unref(files),
  action: uploadApiUrl,
  headers: unref(getHeaders),
  data: unref(getParams),
  accept: unref(getAccept)
    .map(i => (i.startsWith('.') ? i : `.${i}`))
    .join(','),
  ...(props.acceptType === 'img' ? { 'list-type': 'picture-card' } : {}),
  ...omit(attrs, ['headers', 'data']),
} as UploadProps))

function handleBeforeUpload(file: FileType, FileList:FileType[]) {
  const { size, name } = file
  const { maxSize, maxNum } = props

  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    return false
  }

  if (maxNum && maxNum < props.value.length + FileList.length) {
    const errMsg = `最多上传${maxNum}个文件`
    useMessage.error(errMsg)
    // 将文件状态设置为错误
    file.status = 'error'
    file.response = errMsg
    return false
  }

  const accept = unref(getAccept)
  if (accept.length > 0 && !new RegExp(`\\.(${accept.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${accept.join(',')}格式的文件`)

    file.status = 'error'
    return false
  }


  return true
}

let loadingCallback

function handleChange({ file, fileList, event }) {
  try {
    files.value = fileList.filter(item => item.status != 'error') // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件
    if (file.status === 'uploading') {
      loadingCallback = useMessage.loading('上传中...')
      return
    }

    if (file.status !== 'done' && file.status !== 'removed') {
      return
    }
    if (file.status === 'done') {
      if (file.response.type === 'error') {
        useMessage.error(file.response.message)
        files.value = []
        return
      }
    }
    fileIds.value = toRaw(fileList)
      .filter(f => f.status === 'done')
      .map(f => {
        if (f.response) {
          return f.response.data.id
        } else {
          return f.id
        }
      })
    const fileResponseList = toRaw(fileList)
      .filter(f => f.status === 'done')
      .map(f => {
        if (f.response) {
          return {
            status: 'done',
            ...f.response.data
          }
        } else {
          return f
        }
      })
    const data = {
      fileIds: unref(fileIds),
      file: toRaw(file),
      fileList: toRaw(fileList),
      status: file.status
    }
    if (props.limitNum) {
      fileIds.value.splice(0, fileIds.value.length - props.limitNum)
    }
    emits('update:value', props.convert ? unref(fileIds) : fileResponseList)
    emits('change', data)
  } catch (error) {
    console.log('error', error)
    useMessage.error('上传失败')
  } finally {
    if (file.status !== 'uploading') {
      loadingCallback && loadingCallback()
    }
  }
}

const previewVisible = ref(false)
const previewImage = ref('')
const previewTitle = ref('')

function handleCancel() {
  previewVisible.value = false
  previewTitle.value = ''
}
async function handlePreview(file) {
  if (props.acceptType != 'img') {
    const url = file?.response?.result?.url
    if (url) {
      window.open(url, '_blank')
    }
    return
  }
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file?.originFileObj)) as string
  }
  previewImage.value = file.url || file.preview
  previewVisible.value = true
  previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
}

function getBase64(file: File) {
  return new Promise((resolve, reject) => {

    const reader = new FileReader()
    console.log(reader, file)
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader?.result)
    reader.onerror = error => reject(error)
  })
}

</script>
