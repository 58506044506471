import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { redirectRouter } from '@/hooks/router-redirect'
const wageChildrenRoutes: CustomRouteRecordRaw[] = [
  {
    path: 'worker-wage',
    name: 'workerWage',
    component: EmptyLayout,
    meta: {
      title: '发放管理',
      iconfont: 'icon-user-settings-line1',
      requiredPermission: 'WORKER_WAGE',
      hideHistoryPage: true,
      noQuick: true

    },
    children: [
      {
        path: 'wage-bill',
        name: 'wageBill',
        component: () => import('@/views/wage/worker-wage/wage-bill/wage-bill.vue'),
        meta: {
          title: '发薪账单',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-line',
          requiredPermission: 'WAGE_BILL',
        },
      },
      {
        path: 'wage-bill-detail',
        name: 'WORKER_BILL_DETAIL',
        component: () => import('@/views/wage/worker-wage/wage-bill/wage-bill-detail.vue'),
        meta: {
          title: '发薪账单详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'WORKER_BILL_DETAIL',
          parentNameForBreadcrumb: 'wageBill',
          noQuick: true
        },
      },
      {
        path: 'create-wage',
        name: 'CREATE_WAGE',
        component: () => import('@/views/wage/worker-wage/wage-bill/create-wage.vue'),
        meta: {
          title: '创建发薪账单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageBill',
          requiredPermission: 'CREATE_WAGE',
        },
      },
      // 旧版
      {
        path: 'wage-record-list',
        name: 'WORKER_RECORD_LIST',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-list.vue'),
        meta: {
          title: '发薪记录',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-line',
          requiredPermission: 'WORKER_RECORD_LIST',
        },
      },
      // 新版
      {
        path: 'new-wage-record-list',
        name: 'newWageRecordList',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-list2.vue'),
        meta: {
          title: '发薪记录',
          hideHistoryPage: false,
          iconfont: 'icon-bank-card-line',
          requiredPermission: 'NEW_WORKER_RECORD_LIST',
        },
      },
      {
        path: 'wage-record-detail',
        name: 'WORKER_RECORD_DETAIL',
        component: () => import('@/views/wage/worker-wage/wage-record-list/wage-record-detail.vue'),
        meta: {
          title: '发薪记录详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'WORKER_RECORD_DETAIL',
          parentNameForBreadcrumb: 'wageRecordList',
          noQuick: true
        },
      },

      {
        path: 'wage-detail-record-list',
        name: 'wageDetailRecordList',
        component: () => import('@/views/wage/worker-wage/wage-detail-record-list.vue'),
        meta: {
          title: '发放明细',
          hideHistoryPage: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'WAGE_DETAIL_RECORD_LIST', // 之前的和员工详情重复了。。

        },
      },
      {
        path: 'service-charge-bill',
        name: 'serviceChargeBill',
        component: () => import('@/views/wage/worker-wage/service-charge-bill/service-charge-bill.vue'),
        meta: {
          title: '服务费账单',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'SERVICE_CHARGE_BILL',
        },
      },

      // 旧版
      {
        path: 'import-wage',
        name: 'importWage',
        component: () => import('@/views/wage/worker-wage/wage-record-list/import-wage.vue'),
        meta: {
          title: '上传发薪名单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageRecordList',
          requiredPermission: 'IMPORT_WAGE',
        },
      },
      // 新版
      {
        path: 'new-import-wage',
        name: 'NEW_IMPORT_WAGE',
        component: () => import('@/views/wage/worker-wage/wage-record-list/import-wage2.vue'),
        meta: {
          title: '上传发薪名单',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-exchange-cny-fill',
          parentNameForBreadcrumb: 'wageRecordList',
          requiredPermission: 'NEW_IMPORT_WAGE',
        },
      },

    ]
  },
  {
    path: 'invoice-management',
    name: 'invoiceManagement',
    component: EmptyLayout,
    meta: {
      title: '开票管理',
      iconfont: 'icon-coupon-line',
      requiredPermission: 'INVOICE_MANAGEMENT',
      hideHistoryPage: true,
      noQuick: true
    },
    children: [
    // 旧
      {
        path: 'service-charge-invoice-record',
        name: 'serviceChargeInvoiceRecord',
        component: () => import('@/views/wage/worker-wage/service-charge-invoice-record/service-charge-invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-file-list-3-line',
          requiredPermission: 'SERVICE_CHARGE_INVOICE_RECORD',
        },
      },
      // 新
      {
        path: 'management-invoice-record',
        name: 'managementInvoiceRecord',
        component: () => import('@/views/wage/invoice-management/management-invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: false,
          iconfont: 'icon-a-text_snippet_black_24dp1',
          requiredPermission: 'MANAGEMENT_INVOICE_RECORD',
        },
      },
      {
        path: 'invoice-message',
        name: 'invoiceMessage',
        component: () => import('@/views/wage/invoice-management/invoice-message.vue'),
        meta: {
          title: '开票信息',
          hideHistoryPage: false,
          iconfont: 'icon-coupon-3-line',
          requiredPermission: 'INVOICE_MESSAGE',
        },
      },
      {
        path: 'apply-invoice',
        name: 'applyInvoice',
        component: () => import('@/views/wage/invoice-management/apply-invoice.vue'),
        meta: {
          title: '申请开票',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-coupon-3-line',
          requiredPermission: 'APPLY_INVOICE',
        },
      },
      {
        path: 'invoice-record',
        name: 'invoiceRecord',
        component: () => import('@/views/wage/invoice-management/invoice-record.vue'),
        meta: {
          title: '开票记录',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-survey-line',
          requiredPermission: 'INVOICE_RECORD',
        },
      },
      {
        path: 'address-management',
        name: 'addressManagement',
        component: () => import('@/views/wage/invoice-management/address-management.vue'),
        meta: {
          title: '寄送地址管理',
          hideHistoryPage: false,
          iconfont: 'icon-pin-distance-line',
          requiredPermission: 'ADDRESS_MANAGEMENT',
        },
      },
    ]
  },
  {
    path: 'tax-manage',
    name: 'taxManage',
    component: EmptyLayout,
    meta: {
      title: '个税管理',
      iconfont: 'icon-coupon-line',
      requiredPermission: 'TAX_MANAGE',
      hideHistoryPage: true,
      noQuick: true
    },
    children: [
      {
        path: 'salary-list',
        name: 'SALARY_LIST',
        component: () => import('@/views/wage/tax-manage/salary-list.vue'),
        meta: {
          title: '薪资名单',
          iconfont: 'icon-database-2-line',
          requiredPermission: 'SALARY_LIST',
        },
      },
      {
        path: 'additional-deduction',
        name: 'ADDITIONAL_DEDUCTION',
        component: () => import('@/views/wage/tax-manage/additional-deduction.vue'),
        meta: {
          title: '累计专项附加扣除',
          iconfont: 'icon-currency_exchange_black_24dp',
          requiredPermission: 'ADDITIONAL_DEDUCTION',
        },
      },
      {
        path: 'history-tax',
        name: 'HISTORY_TAX',
        component: () => import('@/views/wage/tax-manage/history-tax.vue'),
        meta: {
          title: '历史个税收入',
          iconfont: 'icon-rizhi',
          requiredPermission: 'HISTORY_TAX',
        },
      },
    ]
  },
  {
    path: 'fund-management',
    name: 'fundManagement',
    component: EmptyLayout,
    meta: {
      title: '资金管理',
      iconfont: 'icon-exchange-cny-fill',
      requiredPermission: 'FUND_MANAGEMENT',
      hideHistoryPage: true,
      noQuick: true
    },
    children: [
      {
        path: 'business-wallet',
        name: 'businessWallet',
        component: () => import('@/views/wage/fund-management/business-wallet.vue'),
        meta: {
          title: '商户钱包',
          hideHistoryPage: false,
          iconfont: 'icon-wallet-3-line',
          requiredPermission: 'BUSINESS_WALLET',
          // parentNameForBreadcrumb: 'workerWage'
        },
      },
      {
        path: 'balance-detail-detail',
        name: 'BALANCE_DETAIL_DETAIL',
        component: () => import('@/views/wage/fund-management/balance-detail-detail.vue'),
        meta: {
          title: '余额明细详情',
          hideHistoryPage: false,
          iconfont: 'icon-exchange-cny-fill',
          hideInMenu: true,
          requiredPermission: 'BALANCE_DETAIL_DETAIL',
          parentNameForBreadcrumb: 'sonWallet'

        },

      },
      {
        path: 'son-wallet',
        name: 'sonWallet',
        component: () => import('@/views/wage/fund-management/son-wallet.vue'),
        meta: {
          title: '子账户钱包',
          hideHistoryPage: false,
          iconfont: 'icon-wallet-line',
          requiredPermission: 'SON_WALLET',
          // parentNameForBreadcrumb: 'workerWage'
        },

      },
      {
        path: 'balance-detail',
        name: 'BALANCE_DETAIL',
        component: () => import('@/views/wage/fund-management/balance-detail.vue'),
        meta: {
          title: '流水明细',
          hideHistoryPage: false,
          hideInMenu: true,
          iconfont: 'icon-wallet-line',
          requiredPermission: 'BALANCE_DETAIL',
          parentNameForBreadcrumb: 'sonWallet'

        },
      },
      {
        path: 'wage-account-list',
        name: 'WAGE_ACCOUNT_LIST',
        component: () => import('@/views/wage/fund-management/wage-account-list.vue'),
        meta: {
          title: '发薪账号管理',
          iconfont: 'icon-p2p-line',
          hideHistoryPage: false,
          requiredPermission: 'WAGE_ACCOUNT_LIST',
        },

      },
      {
        path: 'bank-channel-list',
        name: 'BANK_CHANNEL_LIST',
        component: () => import('@/views/wage/fund-management/bank-channel-list.vue'),
        meta: {
          title: '银行渠道管理',
          iconfont: 'icon-article-line1',
          hideHistoryPage: false,
          requiredPermission: 'BANK_CHANNEL_LIST'
        },
      },

    ]

  },
]
export const wageRoutes: CustomRouteRecordRaw = {
  path: 'finance',
  name: 'finance',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(wageChildrenRoutes),
  meta: {
    title: '财务中心',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以财务为维度内容',
    requiredPermission: 'FINANCE',
    needIcon: true,
    noQuick: true
  },
  children: wageChildrenRoutes
}