import { ExtractPropTypes, nextTick } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { Tag, Modal, FormItemRest } from 'ant-design-vue/es'
import { useAppStore } from '@/store'
import { cloneDeep, isArray } from 'lodash-es'
import GridTable from '@/components/table/grid-table.vue'
import { workApi } from '@/api/worker'
import './user-modal-select.css'
export function uniqueFunc(arr: any[], uniId: string) {
  const res = new Map()
  return arr.filter(item => !res.has(item[uniId]) && res.set(item[uniId], 1))
}
const userProps = {
  type: {
    type: String,
    default: 'add'
  },
  value: {
    type: Array
  },
  title: {
    type: String,
    default: '人员'
  },
  hideTag: {
    type: Boolean,
    default: false
  },
  disableUser: {
    type: Array,
    default: []
  },
  isFilterValue: {
    type: Boolean,
    default: false,
  },
  apiParams: {
    type: Object,
    default: {}
  }
}
export type JobManagerSelectProps = Partial<ExtractPropTypes<typeof userProps>>

export default defineComponent({
  name: 'UserModalSelect',
  inheritAttrs: false,
  props: userProps,
  emits: ['change', 'update:value'],
  setup(props, ctx) {
    const { emit, expose } = ctx
    const { getReferenceItemsValueMap, getReferenceLabel, getReferenceItems } = useAppStore()
    const users = ref<any>([])
    const sumitData = ref<any>()
    const visible = ref(false)
    const gridTableEl = ref<IGridTable.Expose>()
    const gridSumitTableEl = ref<IGridTable.Expose>()
    const sumitTableColumns = [
      {
        title: '员工姓名',
        dataIndex: 'realname'
      },
      {
        title: '身份证号',
        dataIndex: 'idcard'
      },
      {
        title: '身份类型',
        dataIndex: 'document_type',

      },
      {
        title: '客户单位',
        dataIndex: 'company',
      },

      {
        title: '岗位',
        dataIndex: 'job_name'
      }
    ]
    const tableColumns = [
      {
        title: '员工姓名',
        dataIndex: 'realname'
      },
      {
        title: '身份证号',
        dataIndex: 'idcard'
      },
      {
        title: '身份类型',
        dataIndex: 'idcardType',
        enumSchemas: getReferenceItems('documentTypeEnum')

      },
      {
        title: '客户单位',
        dataIndex: 'projectCompany.name',
      },
      {
        title: '入职日期',
        dataIndex: 'entryTime'
      },
      {
        title: '岗位',
        dataIndex: 'jobInfo.name'
      },
      {
        title: '手机号',
        dataIndex: 'mobile'
      },
      {
        title: '商保状态',
        dataIndex: 'commercialInsuranceDetailStatus',
        enumSchemas: getReferenceItems('commercialInsuranceDetailStatus'),
      },
      {
        title: '状态',
        dataIndex: 'status',
        enumSchemas: getReferenceItems('staffStatusEnum'),
      }
    ]
    // const addStatusOtion = [
    //   {
    //     label: '已过期',
    //     value: 0
    //   },
    //   {
    //     label: '未参保',
    //     value: 1
    //   },

    // ]
    // const delStatusOtion = [
    //   {
    //     label: '保障中',
    //     value: 3
    //   },

    // ]
    watch(() => props.value, () => {
      if (isArray(props.value)) {
        users.value = cloneDeep(props.value)
      }
    }, {
      immediate: true,
      deep: true
    })
    const sumitTableAttrs = computed(():IGridTable.Props => ({
      // rowStriped: 'none',
      size: 'small',
      showTableHeader: false,
      selectable: false,
      columnSchemas: sumitTableColumns,
      pagination: false,
      dataSource: cloneDeep(sumitData.value)
    }))

    const tableAttrs = computed(():IGridTable.Props => ({
      api: {
        getList: workApi.getWorkList
      },
      size: 'small',
      getListApiParams:

        props.type === 'add' ? {
          ...props.apiParams,
          new_employer_post: 1
        } : {
          ...props.apiParams,
          staff_employer_post: 1
        },
      rowSelection: {
        onSelect: (record, selected) => {
          if (!selected && Array.isArray(users.value)) {
            users.value = users.value.filter(item => item.id != record?.id)
          } else {
            users.value = [...(users.value ?? []), record]
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          if (!selected) {
            const changeKeys = changeRows.map(item => item.id)
            users.value = users.value?.filter(item => !changeKeys.includes(item.id))
          } else {
            users.value = Array.isArray(users.value) && [...users.value, ...changeRows]
          }
        }
      },

      showTableHeader: false,
      exportable: false,
      selectable: true,

      getCheckboxProps: record => ({
        disabled: !record?.jobInfo?.name
      }),
      columnSchemas: tableColumns,
      searchSchemas: [
        {
          field: 'name',
          label: '姓名',
          component: 'Input',
          span: 7,
        },
           {
             label: '入职日期',
             field: 'entryTime',
             component: 'DateRangePicker',
             span: 9,
             componentProps: {
               valueFormat: 'YYYY-MM-DD'
             }
           } as IForm.Schema<'DateRangePicker'>,
      ],
      pagination: {
        // pageSize: 10,
        onChange: () => {
          gridTableEl.value?.setSelectedKeys(unref(users)?.map(item => item.id))
          gridTableEl.value?.setSelectedRows(unref(users))
        }
      }
    }))
    if (props.value && props.value?.length > 0 && JSON.stringify(unref(users)) !== JSON.stringify(unref(props.value))) {
      users.value = cloneDeep(props.value)
    }


    const show = () => {
      visible.value = true
      nextTick(() => {
        gridTableEl.value?.setSelectedKeys(unref(users)?.map(item => item.id))
        gridTableEl.value?.setSelectedRows(unref(users))
      })
    }
    const updateValue = () => {

      // 去重
      users.value = uniqueFunc(users.value, 'id')
      let submitUsers = unref(users.value)
      // 过滤掉props.value已有的数据
      if (props.isFilterValue && Array.isArray(props.value)) {
        submitUsers = submitUsers?.filter(item => (!props.value?.map((item:any) => (item.id))?.includes(item.id)))
      }
      //   提交数据
      let data:any = []
      for (let i = 0; i < unref(submitUsers).length; i++) {
        const item = unref(submitUsers)[i]
        data.push({
          company: item.projectCompany.name,
          document_type: getReferenceLabel('documentTypeEnum', item.idcardType),
          idcard: item.idcard,
          realname: item.realname,
          job_name: item?.jobInfo?.name,

        })
      }
      sumitData.value = cloneDeep(data)
      emit('change', { details: cloneDeep(sumitData.value) })
      emit('update:value', { details: cloneDeep(sumitData.value) })
    }
    const deleteUser = user => {
      users.value = unref(users).filter(item => item.id !== user.id)
      updateValue()
    }

    const handleOk = params => {
      // users.value = gridTableEl.value?.getSelectionRows()
      updateValue()

      visible.value = false
    }

    expose({
      show
    })
    watch(() => sumitData, () => {
      emit('change', { details: cloneDeep(sumitData.value) })
      emit('update:value', { details: cloneDeep(sumitData.value) })
    })
    return () => <div class="user-modal-select">
      <FormItemRest>
        <div class="flex">
          {/* {!props.hideTag && unref(users).map(user => (<Tag closable={true} onClose={() => deleteUser(user)}>{user.name}</Tag>))} */}
          {!props.hideTag && <Tag onClick={show} class="cursor-pointer">
            {props.type === 'add' ? '添加人员' : '减少人员'}
            <PlusOutlined></PlusOutlined>
          </Tag>}
        </div>

        <Modal wrapClassName="user-modal-select-modal" open={visible.value} destroyOnClose={true} onCancel={() => {
          users.value = cloneDeep(props.value ?? [])
          visible.value = false
        }} width={1100} title={`${props.type === 'add' ? '添加' : '减少'}${props.title} `} onOk={handleOk}>
          <GridTable ref={gridTableEl} {...tableAttrs.value}></GridTable>
        </Modal>
        {unref(sumitData)?.length > 0 && <GridTable ref={gridSumitTableEl} {...sumitTableAttrs.value} ></GridTable>}

      </FormItemRest>
    </div>
  }
})
